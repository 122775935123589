import { z } from "zod";

export enum CaseStatus {
  Open = 0,
  InProgress = 1,
  Closed = 2,
  Processed = 3,
}

export type Metadata = Record<string, string>;
export type MetadataValue = {
  key: string;
  value: string;
  displayValue: string;
  dropdownSelection?: MetadataValue;
  child?: {
    key: string;
    value: string;
    displayValue: string;
  };
};

export const caseStatusMetadataSchema = z.object({
  key: z.string(),
  value: z.string(),
  displayValue: z.string(),
});
export type CaseStatusMetadata = z.infer<typeof caseStatusMetadataSchema>;

export const caseStatusSchema: z.Schema<CaseStatus> = z
  .number()
  .min(0)
  .max(3) as any;
