<template>
  <q-dialog v-model="model">
    <Card style="min-width: 60vh">
      <CardHeader>
        {{ t("changelog.changelog") }}
        <template v-if="appStore.amountOfUnseenVersions > 0" #append>
          <q-chip
            color="primary"
            text-color="white"
            size="sm"
            class="pulse animated infinite slow q-ml-sm"
          >
            {{ Math.min(appStore.amountOfUnseenVersions, 10) }}
            {{ appStore.amountOfUnseenVersions > 10 ? "+" : "" }}
            {{ $t("changelog.new_changes") }}
          </q-chip>
        </template>
      </CardHeader>

      <q-card-section
        style="max-height: 60vh; overflow-y: auto"
        class="column no-wrap gap-sm"
      >
        <div v-for="(v, i) in versions" :key="v.number">
          <div class="flex no-wrap justify-between">
            <h3
              style="position: relative; display: inline-block"
              class="grow q-mb-xs"
            >
              {{ $t("changelog.version") }} {{ v.number }}
              <span style="transform: translateX(5px); position: absolute">
                <PingingBadge v-if="i < appStore.amountOfUnseenVersions" />
              </span>
            </h3>
            <div v-if="v.date" class="text-subtitle2 flex gap-xs">
              <span>
                <RenderTimeAgo :time="new Date(v.date)" />
              </span>
              <span>|</span>
              <span>{{ date.formatDate(new Date(v.date), "YYYY-MM-DD") }}</span>
            </div>
          </div>
          <ul class="q-mt-none q-mb-md">
            <li v-for="change in v.changes" :key="change">
              {{ change }}
            </li>
          </ul>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions>
        <q-space />
        <q-btn v-close-popup flat label="close" />
      </q-card-actions>
    </Card>
  </q-dialog>
</template>

<script setup lang="ts">
import { date } from "quasar";
import { onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useAppStore } from "~/stores/appStore";
import versions from "~/versions.json";

const model = defineModel<boolean>({ required: true });
const { t } = useI18n();
const appStore = useAppStore();

onUnmounted(() => appStore.setLastSeenVersionNumber(versions[0].number));
</script>
