
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91analyticsId_93GJ0KTMVOgYMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/analytics/[analyticsId].vue?macro=true";
import { default as document_45authenticityGYGolkQKuAMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/document-authenticity.vue?macro=true";
import { default as historyTXZo9lWDPZMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/history.vue?macro=true";
import { default as booking8jbFZbKRSxMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/booking.vue?macro=true";
import { default as containersvXyuCgCjIIMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/containers.vue?macro=true";
import { default as partiesl4GZb7CljbMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/parties.vue?macro=true";
import { default as sailingsB9qKsuJlc3Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/sailings.vue?macro=true";
import { default as hitsCE6qha8tnmMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits.vue?macro=true";
import { default as inspections52uE8yaxn7Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections.vue?macro=true";
import { default as unusual_45behaviourAEpWQDCwRFMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/unusual-behaviour.vue?macro=true";
import { default as _91caseId_93KD7UbVCGFqMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId].vue?macro=true";
import { default as indexASaiMiqwidMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/index.vue?macro=true";
import { default as indexZ4p8Tl5os0Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/[id]/index.vue?macro=true";
import { default as indexYDuV3WRmjqMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/index.vue?macro=true";
import { default as _91id_93f1ZVNDY4rQMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/[id].vue?macro=true";
import { default as indexdnODzFD1AYMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/index.vue?macro=true";
import { default as _91id_93HYY4Hw5jANMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/[id].vue?macro=true";
import { default as indexCYneOeLBxZMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/index.vue?macro=true";
import { default as newB3Zu0ZSUKoMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/new.vue?macro=true";
import { default as indexnZZsGjsiGrMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/index.vue?macro=true";
import { default as _91id_93WybsLoI1GqMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/[id].vue?macro=true";
import { default as indexgsICGWttBIMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/index.vue?macro=true";
import { default as _91id_9310jtJ9gmuwMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/[id].vue?macro=true";
import { default as indexvzTN0RpLHwMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/index.vue?macro=true";
import { default as newHmbEEYl2SUMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/new.vue?macro=true";
import { default as indexJR8ju5VyjAMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/index.vue?macro=true";
import { default as _91ruleId_93qNZfLHRQB7Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/rules/[ruleId].vue?macro=true";
import { default as indexADa7CMOE4fMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/index.vue?macro=true";
import { default as _91id_93Ax046Lle1GMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/[id].vue?macro=true";
import { default as indexQwnUt5Veo0Meta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/index.vue?macro=true";
import { default as _91id_93bp5WTNa6XbMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/[id].vue?macro=true";
import { default as indexu6owbbCfnDMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/index.vue?macro=true";
import { default as _91id_93kQjytKd83YMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/[id].vue?macro=true";
import { default as indexfSvrdmmwrUMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/index.vue?macro=true";
import { default as team9bRUYBj7tfMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/team.vue?macro=true";
import { default as userSxt1faiXKmMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/user.vue?macro=true";
import { default as settings7XYYhcfYoYMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings.vue?macro=true";
import { default as _91templateId_930OVw1c20GsMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/[templateId].vue?macro=true";
import { default as indexUeKvQprD6oMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/index.vue?macro=true";
import { default as indexojmFkutCLbMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/index.vue?macro=true";
import { default as indexoeujk4XIElMeta } from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/verified-shippers/index.vue?macro=true";
export default [
  {
    name: "analytics-analyticsId",
    path: "/analytics/:analyticsId()",
    meta: _91analyticsId_93GJ0KTMVOgYMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/analytics/[analyticsId].vue")
  },
  {
    name: "cases-caseId",
    path: "/cases/:caseId()",
    meta: _91caseId_93KD7UbVCGFqMeta || {},
    redirect: _91caseId_93KD7UbVCGFqMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId].vue"),
    children: [
  {
    name: "cases-caseId-document-authenticity",
    path: "document-authenticity",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/document-authenticity.vue")
  },
  {
    name: "cases-caseId-history",
    path: "history",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/history.vue")
  },
  {
    name: "cases-caseId-hits",
    path: "hits",
    meta: hitsCE6qha8tnmMeta || {},
    redirect: hitsCE6qha8tnmMeta?.redirect,
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits.vue"),
    children: [
  {
    name: "cases-caseId-hits-booking",
    path: "booking",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/booking.vue")
  },
  {
    name: "cases-caseId-hits-containers",
    path: "containers",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/containers.vue")
  },
  {
    name: "cases-caseId-hits-parties",
    path: "parties",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/parties.vue")
  },
  {
    name: "cases-caseId-hits-sailings",
    path: "sailings",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/hits/sailings.vue")
  }
]
  },
  {
    name: "cases-caseId-inspections",
    path: "inspections",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/inspections.vue")
  },
  {
    name: "cases-caseId-unusual-behaviour",
    path: "unusual-behaviour",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/[caseId]/unusual-behaviour.vue")
  }
]
  },
  {
    name: "cases",
    path: "/cases",
    meta: indexASaiMiqwidMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/cases/index.vue")
  },
  {
    name: "data-blocks-id",
    path: "/data-blocks/:id()",
    meta: indexZ4p8Tl5os0Meta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/[id]/index.vue")
  },
  {
    name: "data-blocks",
    path: "/data-blocks",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/data-blocks/index.vue")
  },
  {
    name: "email-templates-id",
    path: "/email-templates/:id()",
    meta: _91id_93f1ZVNDY4rQMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/[id].vue")
  },
  {
    name: "email-templates",
    path: "/email-templates",
    meta: indexdnODzFD1AYMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/email-templates/index.vue")
  },
  {
    name: "incidents-id",
    path: "/incidents/:id()",
    meta: _91id_93HYY4Hw5jANMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/[id].vue")
  },
  {
    name: "incidents",
    path: "/incidents",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/index.vue")
  },
  {
    name: "incidents-new",
    path: "/incidents/new",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/incidents/new.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/index.vue")
  },
  {
    name: "inspections-inspections-id",
    path: "/inspections/inspections/:id()",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/[id].vue")
  },
  {
    name: "inspections-inspections",
    path: "/inspections/inspections",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/inspections/index.vue")
  },
  {
    name: "inspections-surveyors-id",
    path: "/inspections/surveyors/:id()",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/[id].vue")
  },
  {
    name: "inspections-surveyors",
    path: "/inspections/surveyors",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/index.vue")
  },
  {
    name: "inspections-surveyors-new",
    path: "/inspections/surveyors/new",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/inspections/surveyors/new.vue")
  },
  {
    name: "libraries-id",
    path: "/libraries/:id()",
    meta: indexJR8ju5VyjAMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/index.vue")
  },
  {
    name: "libraries-id-rules-ruleId",
    path: "/libraries/:id()/rules/:ruleId()",
    meta: _91ruleId_93qNZfLHRQB7Meta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/[id]/rules/[ruleId].vue")
  },
  {
    name: "libraries",
    path: "/libraries",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/libraries/index.vue")
  },
  {
    name: "policies-id",
    path: "/policies/:id()",
    meta: _91id_93Ax046Lle1GMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/[id].vue")
  },
  {
    name: "policies",
    path: "/policies",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/policies/index.vue")
  },
  {
    name: "service-hooks-id",
    path: "/service-hooks/:id()",
    meta: _91id_93bp5WTNa6XbMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/[id].vue")
  },
  {
    name: "service-hooks",
    path: "/service-hooks",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/service-hooks/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settings7XYYhcfYoYMeta || {},
    redirect: "/settings/user",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings.vue"),
    children: [
  {
    name: "settings-config-id",
    path: "config/:id()",
    meta: _91id_93kQjytKd83YMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/[id].vue")
  },
  {
    name: "settings-config",
    path: "config",
    meta: indexfSvrdmmwrUMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/config/index.vue")
  },
  {
    name: "settings-team",
    path: "team",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/team.vue")
  },
  {
    name: "settings-user",
    path: "user",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/settings/user.vue")
  }
]
  },
  {
    name: "template-groups-id-templateId",
    path: "/template-groups/:id()/:templateId()",
    meta: _91templateId_930OVw1c20GsMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/[templateId].vue")
  },
  {
    name: "template-groups-id",
    path: "/template-groups/:id()",
    meta: indexUeKvQprD6oMeta || {},
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/[id]/index.vue")
  },
  {
    name: "template-groups",
    path: "/template-groups",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/template-groups/index.vue")
  },
  {
    name: "verified-shippers",
    path: "/verified-shippers",
    component: () => import("/azp/_work/1/s/web/nuxt.hazcheckdetect.com/pages/verified-shippers/index.vue")
  }
]