import payload_plugin_qLmFnukI99 from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import custom_serialiser_BWW3aRwjlz from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/plugins/custom-serialiser.ts";
import revive_payload_client_4sVQNw7RlN from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_tOyKf6MBtG from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nlo/nuxt-application-insights/dist/runtime/plugin.client.mjs";
import plugin_client_OVoKJro5pc from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_prod_client_jEf4DH9FfC from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_jg3TSEXw60 from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@hebilicious/vue-query-nuxt/dist/runtime/plugin.mjs";
import plugin_tMGwffvjBc from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import plugin_sb2LSEEy8f from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/plugins/api.ts";
import country_flag_BikPvrkSrm from "/azp/_work/1/s/web/nuxt.hazcheckdetect.com/plugins/country-flag.ts";
export default [
  payload_plugin_qLmFnukI99,
  custom_serialiser_BWW3aRwjlz,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_tOyKf6MBtG,
  plugin_client_OVoKJro5pc,
  plugin_prod_client_jEf4DH9FfC,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_jg3TSEXw60,
  plugin_tMGwffvjBc,
  plugin_sb2LSEEy8f,
  plugin_AUP22rrBAc,
  api_GFfDXud5Cr,
  country_flag_BikPvrkSrm
]