import { computed } from "vue";
import { defineStore } from "pinia";
import { LocalStorage } from "quasar";
import versions from "../versions.json";

const versionNumberKey = "lastSeenVersion";

export const useAppStore = defineStore("app", () => {
  const getVersionNumberFromLocalStorage = () => {
    const lastSeenVersionNumber: string | null = LocalStorage.has(
      versionNumberKey
    )
      ? LocalStorage.getItem(versionNumberKey)
      : null;

    return lastSeenVersionNumber;
  };

  const lastSeenVersionNumber = computed(() =>
    getVersionNumberFromLocalStorage()
  );

  const setLastSeenVersionNumber = (versionNumber: string) =>
    LocalStorage.set(versionNumberKey, versionNumber);

  const amountOfUnseenVersions = computed(() => {
    if (lastSeenVersionNumber.value == null) return versions.length;

    const index = versions.findIndex(
      (x) => x.number === lastSeenVersionNumber.value
    );

    return index === -1 ? versions.length : index;
  });

  return {
    lastSeenVersionNumber,
    amountOfUnseenVersions,
    setLastSeenVersionNumber,
  };
});
