import type { CaseStatus, MetadataValue } from "~/src/models/Case/Shared.model";
import {
  deserialiseDate,
  type DeserialiseDates,
  type SerialisedDate,
} from "../utils/Serialise.model";
import type { BackendScreenResultViewModel } from "./Screen.model";
import type { EmailAddress } from "../Email/Email.model";

export type BackendBaseHistoryEntry = {
  createdAt: SerialisedDate;
  createdBy: string;
};

export type BackendScreenResultHistoryEntry = BackendBaseHistoryEntry &
  BackendScreenResultViewModel & {
    type: "screenresult";
  };

export type BackendCaseStatusEntry = BackendBaseHistoryEntry & {
  status: CaseStatus;
  metadata?: MetadataValue[];
  type: "status";
};
export type BackendCaseNoteEntry = BackendBaseHistoryEntry & {
  createdByEmail: string;
  text: string;
  type: "casenote";
};
export type BackendAssignmentEntry = BackendBaseHistoryEntry & {
  /**  when undefined, user has Unassigned */
  assignedTo?: string;
  type: "assignment";
};
export type BackendDueDateEntry = BackendBaseHistoryEntry & {
  dueDate?: SerialisedDate;
  type: "dueDate";
};
export type BackendCaseHistoryEntry =
  | BackendScreenResultHistoryEntry
  | BackendDueDateEntry
  | BackendAssignmentEntry
  | BackendCaseStatusEntry
  | BackendCaseNoteEntry;

export type BackendCaseEmailEntry = BackendBaseHistoryEntry & {
  recipients: EmailAddress[];
  body: string;
  subject: string;
};

export type CaseStatusEntry = DeserialiseDates<BackendCaseStatusEntry>;
export type CaseNoteEntry = DeserialiseDates<BackendCaseNoteEntry>;
export type AssignmentEntry = DeserialiseDates<BackendAssignmentEntry>;
export type DueDateEntry = DeserialiseDates<BackendDueDateEntry>;
export type ScreenEntry = DeserialiseDates<BackendScreenResultHistoryEntry>;
export type CaseHistoryEntry =
  | ScreenEntry
  | DueDateEntry
  | AssignmentEntry
  | CaseStatusEntry
  | CaseNoteEntry;

export type EmailHistoryEntryViewModel = {
  recipients: string[];
  body: string;
  createdAt: SerialisedDate;
  createdBy: string;
  type: "email";
};

// Takes out relevant history data from Case and sorts

export type BffCaseHistoryViewModel =
  | BackendCaseHistoryEntry
  | BackendScreenResultHistoryEntry
  | BffEmailHistoryEntry;

export type BackendCaseHistoryGetEntry =
  | BackendCaseStatusEntry
  | BackendAssignmentEntry
  | BackendDueDateEntry;
export type CaseHistoryGetEntry =
  | CaseStatusEntry
  | AssignmentEntry
  | DueDateEntry;

export type BackendCaseEmail = BackendBaseHistoryEntry & {
  recipients: EmailAddress[];
  body: string;
  subject: string;
};

export type CaseHistoryItemType = BffCaseHistoryViewModel["type"];

export const sortByDate = (result: { createdAt: SerialisedDate }[]) => {
  result.sort((a, b) =>
    deserialiseDate(a.createdAt) < deserialiseDate(b.createdAt) ? 1 : -1
  );
};

export function deserialiseCaseHistoryEntry(entry: BackendCaseHistoryEntry) {
  const ret = entry as any as CaseHistoryEntry;
  ret.createdAt = deserialiseDate(entry.createdAt);
  if ("dueDate" in ret && ret.dueDate !== undefined)
    ret.dueDate = deserialiseDate((entry as any).dueDate);
  return ret;
}

export const dueDate = (entry: BackendDueDateEntry) => {
  return {
    type: "date",
    date: entry.createdAt,
    dueDate: entry.dueDate,
    createdBy: entry.createdBy,
  } as const;
};
type BffCaseHistoryDuteDateComponent = ReturnType<typeof dueDate>;

const assignment = (entry: BackendAssignmentEntry) => {
  return {
    type: "assignment",
    date: entry.createdAt,
    assignedTo: entry.assignedTo,
    createdBy: entry.createdBy,
  } as const;
};
type BffCaseHistoryAssignmentComponent = ReturnType<typeof assignment>;

const status = (entry: BackendCaseStatusEntry) => {
  return {
    createdBy: entry.createdBy,
    type: "status",
    status: entry.status,
    date: entry.createdAt,
    metadata: entry.metadata,
  } as const;
};
type BffCaseHistoryStatusComponent = ReturnType<typeof status>;

const caseNote = (entry: BackendCaseNoteEntry) => {
  return {
    type: "note",
    date: entry.createdAt,
    createdBy: entry.createdBy,
    createdByEmail: entry.createdByEmail,
    text: entry.text,
  } as const;
};
type BffCaseHistoryNoteComponent = ReturnType<typeof caseNote>;

const screenResult = (entry: BackendScreenResultHistoryEntry) => {
  return {
    type: "screen",
    date: entry.ingestionDate,
    createdAt: entry.createdAt,
    createdBy: entry.createdBy,
    screeningId: entry.screeningId,
    hits: entry.hits.length,
  } as const;
};
type BffCaseHistoryScreenResultComponent = ReturnType<typeof screenResult>;
export type CaseHistoryScreenResultComponent =
  DeserialiseDates<BffCaseHistoryScreenResultComponent>;

const email = (entry: BffEmailHistoryEntry) => {
  return {
    type: "email",
    date: entry.createdAt,
    createdBy: entry.createdBy,
    recipients: entry.recipients.length,
  } as const;
};
type BffCaseHistoryEmailComponent = ReturnType<typeof email>;

export const mapEmails = (
  emails: BackendCaseEmail[]
): BffEmailHistoryEntry[] => {
  return emails.map((email) => {
    return {
      recipients: email.recipients.map((recipient) => recipient.address),
      body: email.body,
      createdBy: email.createdBy,
      createdAt: email.createdAt,
      type: "email",
    };
  });
};

export const mapHistoryItem = (
  entry: BffCaseHistoryViewModel
): BffCaseHistoryComponentData => {
  if (entry.type === "assignment") return assignment(entry);
  else if (entry.type === "casenote") return caseNote(entry);
  else if (entry.type === "status") return status(entry);
  else if (entry.type === "screenresult") return screenResult(entry);
  else if (entry.type === "email") return email(entry);
  else if (entry.type === "dueDate") return dueDate(entry);

  const _exhaustiveCheck: never = entry;
  return entry;
};

export type BffCaseHistoryComponentData =
  | BffCaseHistoryDuteDateComponent
  | BffCaseHistoryAssignmentComponent
  | BffCaseHistoryStatusComponent
  | BffCaseHistoryNoteComponent
  | BffCaseHistoryScreenResultComponent
  | BffCaseHistoryEmailComponent;
export type CaseHistoryComponentData =
  DeserialiseDates<BffCaseHistoryComponentData>;

export function deserialiseCaseHistoryComponentData(
  el: BffCaseHistoryComponentData
) {
  const ret = el as any as CaseHistoryComponentData;
  if ("date" in el) {
    const date = deserialiseDate(el.date);
    (ret as any).date = date;
  }
  return ret;
}

export type BffEmailHistoryEntry = BackendBaseHistoryEntry & {
  recipients: string[];
  body: string;
  type: "email";
};

export function getKeypath(item: CaseHistoryComponentData): string {
  if (item.type === "date") {
    if (item.dueDate) return "case.history.item.due_date.set";
    return "case.history.item.due_date.removed";
  }
  if (item.type === "assignment") {
    const selfAssigned = item.assignedTo === item.createdBy;

    if (!item.assignedTo) return "case.history.item.assignment.unassigned";
    else if (selfAssigned) return "case.history.item.assignment.self_assigned";
    else return "case.history.item.assignment.assigned";
  }
  if (item.type === "status") return "case.history.item.status";
  if (item.type === "note") return "case.history.item.note";
  if (item.type === "screen") return "case.history.item.screen_result.body";
  if (item.type === "email") return "case.history.item.email";

  return "";
}
