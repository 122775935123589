<template>
  <!-- Stops hydration issues. Just locale things :) -->
  <ClientOnly>
    {{ formattedDateDisplay }}
    <template #placeholder>
      <q-skeleton width="22ch" />
    </template>
  </ClientOnly>
</template>

<script setup lang="ts">
import { ClientOnly } from "#components";
import { computed } from "vue";
import { format as formatDate } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import type { SerialisedDate } from "~/src/models/utils/Serialise.model";

const props = withDefaults(
  defineProps<{
    time?: Date | SerialisedDate;
    format?: string;
    local?: boolean;
  }>(),
  { time: undefined, format: "yyyy-MM-dd HH:mm", local: true }
);

const formattedDateDisplay = computed(() => {
  if (!props.time) return " - ";
  if (props.local) return formatDate(props.time, props.format);
  return formatInTimeZone(props.time, "UTC", props.format);
});
</script>
