import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useConfigurationService } from "~/src/services/ConfigurationService";
import { DEFAULT_REQUEST, useCaseTableStore } from "~/stores/caseTableStore";
import { useState } from "#app";
import { _CloneDeep } from "#imports";

import type { FrontendRequestObject } from "~/src/models/utils/Api.model";
import type {
  FilterConfigResponse,
  FrontendQueryFilter,
} from "~/src/models/utils/Filter.model";

export const useCaseFilters = () => {
  const caseTableStore = useCaseTableStore();
  const { request } = storeToRefs(caseTableStore);
  const filters = ref<FrontendQueryFilter[]>(request.value?.filters ?? []);

  const hasInitialised = useState("hasCaseFiltersInitialised", () => false);
  const filterConfig = useState<undefined | FilterConfigResponse>(
    "filterConfig",
    () => undefined
  );
  const { getCaseTableFilterConfigCached } = useConfigurationService();

  const init = async () => {
    if (hasInitialised.value) return;

    filterConfig.value = await getCaseTableFilterConfigCached();
    if (defaultFilter.value) setDefaultFilter([defaultFilter.value]);
    hasInitialised.value = true;
  };

  const resetRequest = async () => {
    if (!hasInitialised.value) await init();
    filters.value = _CloneDeep(defaultVal.value.filters ?? []);
    request.value = _CloneDeep(defaultVal.value);

    hasInitialised.value = true;
  };

  // DEFAULT FILTER
  const defaultFilter = computed(() => filterConfig.value?.defaultFilter);
  const defaultVal = ref<FrontendRequestObject>(DEFAULT_REQUEST);

  const setDefaultFilter = (filter: FrontendRequestObject["filters"]) => {
    defaultVal.value.filters = filter;
  };

  if (defaultFilter.value) setDefaultFilter([defaultFilter.value]);

  return {
    init,
    defaultVal,
    filterConfig,
    resetRequest,
    hasInitialised,
    filters,
  };
};
