<template>
  <UserAvatar v-bind="$attrs" :user="user" class="cursor-pointer">
    <q-menu v-if="hasMenu">
      <q-list style="min-width: 250px">
        <q-item v-if="user.name">
          <q-item-section avatar>
            <UserAvatar v-bind="$attrs" :user="user" />
          </q-item-section>

          <q-item-section>
            <q-item-label style="margin-bottom: -2px">
              {{ user.name }}
            </q-item-label>

            <q-item-label caption class="q-mt-xs">
              <span class="column gap-xs" style="gap: 2px">
                <span class="ellipsis" style="max-width: 300px">
                  {{ user.email }}
                </span>
                <span class="ellipsis" style="max-width: 300px">
                  {{ teamName }}
                </span>
              </span>
            </q-item-label>
          </q-item-section>
        </q-item>

        <q-separator />
        <q-item v-close-popup clickable to="/settings">
          <q-item-section side><q-icon name="mdi-cog" /></q-item-section>

          <q-item-section class="text-capitalize">
            {{ $t("user.settings") }}
          </q-item-section>
        </q-item>

        <q-item
          v-close-popup
          clickable
          data-testId="logoutButton"
          :href="getLogoutUrl()"
        >
          <q-item-section side><q-icon name="mdi-logout" /></q-item-section>

          <q-item-section class="text-capitalize text-no-wrap">
            {{ $t("user.sign_out") }}
          </q-item-section>
        </q-item>

        <q-separator />

        <q-item clickable @click="dialogOpen = true">
          <q-item-section class="text-subtitle2 text-center">
            <span style="position: relative">
              v{{ versions[0].number }}

              <PingingBadge v-if="showUpdatePinger" />
            </span>
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>

    <slot />
  </UserAvatar>

  <ChangelogDialog v-model="dialogOpen" />
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import useAuth from "~/composables/useAuth";
import { UserAvatar } from "#components";
import versions from "~/versions.json";
import { useAppStore } from "~/stores/appStore";

import type { UserInformation } from "~/src/models/User.model";

defineProps<{
  hasMenu?: boolean;
}>();

const appStore = useAppStore();
const { getLogoutUrl, user: userData, teamName } = await useAuth();
const dialogOpen = ref(false);

const showUpdatePinger = computed(() => appStore.amountOfUnseenVersions > 0);

const user = computed<Omit<UserInformation, "isHidden">>(() => ({
  email: userData.value?.email ?? "",
  name: userData.value?.name ?? "",
  id: userData.value?.keycloakId ?? "",
}));
</script>
