import { useRuleService } from "~/src/services/RuleService";
import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";

export default defineNuxtRouteMiddleware(async (to) => {
  const { useGetRuleQuery } = useRuleService();
  if (to.params.ruleId === "new") return true;

  const result = await useGetRuleQuery(
    String(to.params.id),
    String(to.params.ruleId)
  );

  if (!result.data.value)
    return abortNavigation(
      createError({
        statusCode: 404,
        message: "Rule not found",
      })
    );
});
