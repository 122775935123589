import { useQueryClient } from "@tanstack/vue-query";
import useNotify from "~/composables/useNotify";
import { getErrorMessagesFromError } from "~/utils/helpers";
import { useOurNuxtApp } from "~/utils/nuxt";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";

import type { LibraryViewModel } from "../models/Library/Library.viewmodel";
import { unref, type MaybeRef } from "vue";
import type { LibrariesGetQuery } from "~/server/api/hcd/libraries/index.get";

type AddLibraryPayload = {
  name: string;
  description?: string;
  internalNotes?: string;
  metadata?: LibraryViewModel["metadata"];
  filter?: string;
};

type ListLibrariesRequest = {
  localOrShared: "shared" | "local";
  page?: number;
  pageSize?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
};

const endpoints = {
  list: "/api/hcd/libraries",
  create: "/api/hcd/libraries",
  get: parseUriTemplate("/api/hcd/libraries/{id}"),
  update: parseUriTemplate("/api/hcd/libraries/{id}"),
  delete: parseUriTemplate("/api/hcd/libraries/{id}"),
  enable: parseUriTemplate("/api/hcd/libraries/{id}/enable"),
  disable: parseUriTemplate("/api/hcd/libraries/{id}/disable"),
  export: parseUriTemplate("/api/hcd/libraries/{id}/export"),
  import: "/api/hcd/libraries/import",
} as const;

export const useLibraryService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();

  const listLibraries = (query: ListLibrariesRequest, signal?: AbortSignal) =>
    $api(endpoints.list, {
      query: query satisfies LibrariesGetQuery,
      signal,
    });

  const useListLibrariesQuery = (
    req = {
      page: 1,
      pageSize: 20,
    } as ListLibrariesRequest,
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Libraries.list, req],
      ({ signal }) =>
        listLibraries(
          {
            ...req,
            localOrShared: req.localOrShared,
          },
          signal
        ),
      {
        createNuxtError,
      }
    );

  const getLibrary = (id: string, signal?: AbortSignal) =>
    $api(endpoints.get.expand({ id }), { signal });

  const useGetLibraryQuery = (id: MaybeRef<string>, options?: MyQueryOptions) =>
    createQuery(
      [QUERY_KEYS.Libraries.get, id],
      ({ signal }) => getLibrary(unref(id), signal),
      options
    );

  const createLibrary = (body: AddLibraryPayload) =>
    $api(endpoints.create, {
      method: "POST",
      body,
    });

  const useCreateLibraryMutation = () =>
    createMutation((payload: AddLibraryPayload) => createLibrary(payload), {
      onError() {
        notifyError(t("error.adding_library"));
      },
      onSuccess() {
        notifySuccess(t("success.success"), "success.library_added");
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Libraries.list],
        });
      },
    });

  const updateLibrary = (id: string, payload: AddLibraryPayload) =>
    $api(endpoints.update.expand({ id }), {
      method: "PUT",
      body: payload,
    });

  const useUpdateLibraryMutation = () =>
    createMutation(
      ({ id, payload }: { id: string; payload: AddLibraryPayload }) =>
        updateLibrary(id, payload),
      {
        onSuccess: (_x, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.get, y.id],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.list],
          });
          notifySuccess(t("success.success"), "success.library_updated");
        },
        onError: () => {
          notifyError(t("error.updating_library"));
        },
      }
    );

  // Delete Library
  // goes through catchall proxy route
  const deleteLibrary = (id: string) =>
    $api<undefined>(endpoints.delete.expand({ id }), {
      method: "DELETE",
    });

  const useDeleteLibraryMutation = () =>
    createMutation(({ id }: { id: string }) => deleteLibrary(id), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Libraries.list],
        });
        notifySuccess(t("success.success"), "success.library_deleted");
      },
      onError: () => {
        notifyError("error.deleting_library");
      },
    });

  // Enable / Disable Library
  // goes through catchall proxy route
  const setLibraryStatus = (id: string, enable: boolean) => {
    const endpoint = enable ? endpoints.enable : endpoints.disable;
    return $api<undefined>(endpoint.expand({ id }), {
      method: "PUT",
    });
  };

  const useSetLibraryStatusMutation = () =>
    createMutation(
      ({ id, enable }: { id: string; enable: boolean }) =>
        setLibraryStatus(id, enable),
      {
        onSuccess: (_, { id, enable }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.get, id],
          });
          notifySuccess(
            t("success.success"),
            enable
              ? t("success.library_enabled")
              : t("success.library_disabled")
          );
        },
        onError: (_error, library) =>
          library.enable
            ? notifyError(t("error.library_enabled"))
            : notifyError(t("error.library_disabled")),
      }
    );

  const exportLibrary = async (id: string) => {
    const res = await $api.raw(endpoints.export.expand({ id }), {
      method: "GET",
      responseType: "blob",
    });

    let filename = "";
    const disposition = res.headers.get("Content-Disposition");
    if (disposition && disposition.indexOf("attachment") !== -1) {
      const filenameRegex = /filename=((\\?['"])(.*?)\2|([^;\n]*))/;
      const matches = filenameRegex.exec(disposition);
      if (matches) {
        filename = (matches[3] ?? "") + (matches[4] ?? "");
      }
    }
    if (!filename) {
      filename = `library-${id}.json`;
    }

    const data = res._data;
    if (!data || !(data instanceof Blob)) return null;

    return {
      data,
      filename,
    };
  };

  const useExportLibraryMutation = (
    onSuccess?: (x: Awaited<ReturnType<typeof exportLibrary>>) => unknown
  ) =>
    createMutation((id: string) => exportLibrary(id), {
      onSuccess,
      onError: () => {
        notifyError(t("library.export_error"));
      },
    });

  const importLibrary = (body: { importFile: File; password: string }) => {
    const formData = new FormData();
    formData.append("importFile", body.importFile);
    return $api(endpoints.import, {
      method: "POST",
      body: formData,
    });
  };

  const useImportLibraryMutation = () =>
    createMutation(
      (body: { importFile: File; password: string }) => importLibrary(body),
      {
        onSuccess: () => {
          notifySuccess(t("success.success"), t("success.library_imported"));
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Libraries.list],
          });
        },
        onError: (error) =>
          notifyError(getErrorMessagesFromError(error).join("\n")),
      }
    );

  return {
    exportLibrary,
    listLibraries,
    getLibrary,

    useExportLibraryMutation,
    useListLibrariesQuery,
    useCreateLibraryMutation,
    useGetLibraryQuery,
    useUpdateLibraryMutation,
    useDeleteLibraryMutation,
    useSetLibraryStatusMutation,
    useImportLibraryMutation,
  };
};
