<template>
  <div
    :class="props.class"
    class="flex no-wrap full-width items-stretch"
    style="align-items: stretch"
  >
    <q-card-section
      v-bind="$attrs"
      style="flex-grow: 1"
      :class="[
        dense ? 'q-py-sm' : undefined,
        collapsible ? 'q-pr-none' : undefined,
      ]"
    >
      <div class="flex justify-between items-center no-wrap">
        <div class="flex items-center">
          <slot name="prepend" />
          <component :is="is || 'h3'"><slot /></component>
          <slot name="hint">
            <span v-if="hint" class="text-subtitle2 q-ml-sm">
              {{ hint }}
            </span>
          </slot>
          <slot name="append" />
        </div>
        <slot name="center" />
        <slot name="icon">
          <div class="flex no-wrap justify-end items-center gap-md">
            <slot name="before-icon" />
            <HazcheckIcon
              v-if="icon === 'hazcheck_h'"
              class="icon icon--grey icon--card"
            />
            <div v-else>
              <q-icon
                v-if="icon"
                :name="icon"
                class="icon icon--grey icon--card"
              />
            </div>
          </div>
        </slot>
      </div>
    </q-card-section>
    <div v-if="collapsible" class="flex no-wrap" style="align-self: stretch">
      <q-separator vertical />
      <Button
        stretch
        flat
        class=""
        :icon="modelValue ? 'mdi-menu-up' : 'mdi-menu-down'"
        @click="toggleCollapse"
      />
    </div>
  </div>
  <q-separator v-if="!borderless" />
</template>

<script lang="ts" setup>
import { HazcheckIcon } from "#components";
import { computed } from "vue";

const props = defineProps<{
  icon?: string;
  collapsible?: boolean;
  modelValue?: boolean;
  borderless?: boolean;
  hint?: string;
  dense?: boolean;
  is?: string;
  class?: string;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", collapsed: boolean): void;
}>();

const toggleCollapse = () => {
  localCollapsed.value = !localCollapsed.value;
};

const localCollapsed = computed({
  get: () => props.modelValue as boolean,
  set: (x: boolean) => emit("update:modelValue", x),
});
</script>

<style lang="scss" scoped>
.icon--collapse {
  cursor: pointer;
}
</style>
