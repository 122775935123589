import { inject, provide, type Ref, ref } from "vue";
import type { Description } from "~/src/models/Case/Booking.viewmodel";

const JSON_PATH_ROOT = "$";

export class PathContextBuilder {
  public path: string[] = [JSON_PATH_ROOT];

  constructor(initial?: PathContextBuilder) {
    if (initial) {
      this.path = [...initial.path];
    }
  }

  provideContext() {
    provide("bookingPath", ref(this.path));
    return this;
  }

  fromContext() {
    const injectedValue = inject<Ref<string[]>>("bookingPath")?.value;
    if (!injectedValue) throw new Error("Path context not created");
    this.path = [...injectedValue];
    return this;
  }

  withProperty(path: string) {
    this.path.push(path);
    return this;
  }

  // inserts an index in the last path element if possible
  withIndex(index: number) {
    const last = this.path[this.path.length - 1];
    const base = last.replace(/\[\d+\]/, "");
    this.path[this.path.length - 1] = `${base}[${index}]`;
    return this;
  }

  withKeyValueArray(key: string, source: string) {
    const last = this.path[this.path.length - 1];
    const base = last.replace(/\[\?\s*]/, "");
    this.path[this.path.length - 1] = `${base}[?(@.${key} == '${source}')]`;
    return this;
  }

  build() {
    return this.path.join(".");
  }
}

export type Path = InstanceType<typeof PathContextBuilder>;

export function parseCtuIndexFromField(field: string) {
  const match = field.match(ctuRegEx);
  if (!match) return null;
  return Number(match[1]);
}
const ctuRegEx = /cargo-transport-units\[(\d+)\]/i;

export function parseCargoIndexFromField(field: string) {
  const match = field.match(cargoRegex);
  if (!match) return null;
  return Number(match[1]);
}
const cargoRegex = /cargo\[(\d+)\]/i;

export function parseCtuAndCargoIndexFromField(
  field: string
): [number, number] | null {
  const match = field.match(ctuAndCargoRegex);
  if (!match) return null;
  return [Number(match[1]), Number(match[2])];
}
const ctuAndCargoRegex = /cargo-transport-units\[(\d+)\]\.cargo\[(\d+)\]/i;

export function parseCtuAndMaybeCargoIndexFromField(
  field: string
): [number, number | null] | null {
  const ctuIndex = parseCtuIndexFromField(field);
  if (ctuIndex === null) return null;
  const cargoIndex = parseCargoIndexFromField(field);
  return [ctuIndex, cargoIndex];
}

export function parsePartyIndexFromPath(path: string) {
  const match = partyRegex.exec(path);
  return match ? parseInt(match[1]) : null;
}
const partyRegex = /parties\[(\d+)\]/;

export function parseSailingIndexFromPath(path: string) {
  const match = sailingRegex.exec(path);
  if (match) {
    return parseInt(match[1]);
  }
  return null;
}
const sailingRegex = /sailings\[(\d+)\]/;

export function parseStageIndexFromPath(path: string) {
  const match = stageRegex.exec(path);
  if (match) {
    return parseInt(match[1]);
  }
  return null;
}
const stageRegex = /stages\[(\d+)\]/;

export function parseSailingAndMaybeStageIndexFromField(
  field: string
): [number, number | null] | null {
  const sailingIndex = parseSailingIndexFromPath(field);
  if (sailingIndex === null) return null;
  const stageIndex = parseStageIndexFromPath(field);
  return [sailingIndex, stageIndex];
}

/** Can be accessed by index or by $id or source */
export function getPathsForDescription(
  path: Path,
  desc: Description,
  index: number,
  text: boolean = true
): Set<string> {
  const allPossiblePaths = new Set<string>();

  allPossiblePaths.add(
    new PathContextBuilder(path).withIndex(index).withProperty("text").build()
  );

  if (desc.$id) {
    let builder = new PathContextBuilder(path).withKeyValueArray(
      "$id",
      desc.$id
    );
    if (text) builder = builder.withProperty("text");
    allPossiblePaths.add(builder.build());
  }
  if (desc.source) {
    let builder = new PathContextBuilder(path).withKeyValueArray(
      "source",
      desc.source
    );
    if (text) builder = builder.withProperty("text");
    allPossiblePaths.add(builder.build());
  }

  return allPossiblePaths;
}
