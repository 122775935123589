<template>
  <span :class="['parent  no-wrap flex', horizontal ? 'gap-sm' : 'column ']">
    <span>{{ timeAgo }}</span>
    <q-tooltip v-if="props.hover" class="text-caption" :offset="[0, 5]">
      <RenderDateTime :time="time" :format="format" />
    </q-tooltip>
  </span>
</template>

<script setup lang="ts">
import { getTimeAgo } from "~/utils/dateHelpers";
import { RenderDateTime } from "#components";
import { computed } from "vue";
import {
  deserialiseDate,
  type SerialisedDate,
} from "~/src/models/utils/Serialise.model";

const props = defineProps<{
  time?: Date | SerialisedDate;
  alwaysDisplay?: boolean;
  horizontal?: boolean;
  hideTime?: boolean;
  format?: string;
  hover?: boolean;
  strict?: boolean;
}>();

const timeAgo = computed(() => {
  if (props.time instanceof Date) {
    return getTimeAgo(props.time, props.strict ?? true);
  }
  if (props.time)
    return getTimeAgo(deserialiseDate(props.time), props.strict ?? true);
  return " - ";
});
</script>

<style lang="scss" scoped>
.formattedTime {
  margin-top: -4px;
  display: none;
  font-size: 12px;
}

.parent:hover {
  .formattedTime {
    display: block;
  }
}
</style>
