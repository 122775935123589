<template>
  <NuxtLoadingIndicator color="var(--q-primary)" />
  <NuxtLayout>
    <NuxtPage :transition="{ mode: 'in-out' }" />
  </NuxtLayout>
  <DialogConfirm />
</template>
<script setup lang="ts">
import { useHead, useRuntimeConfig } from "#imports";

const config = useRuntimeConfig();

useHead({
  titleTemplate: (x) => {
    let title = "Hazcheck Detect";

    if (config.public.environment && config.public.environment !== "prod") {
      if (config.public.environment === "local") title = `[✨local✨] ${title}`;
      else title = `[${config.public.environment}] ${title}`;
    }
    if (x) {
      title += ` | ${x}`;
    }

    return title;
  },
});
</script>
