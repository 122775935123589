<template>
  <q-btn
    v-bind="props"
    :color="color === 'primary' ? undefined : color"
    :class="{
      'bg-primary-strong': color === 'primary' && !flat,
      'text-white': color === 'primary' && !flat,
      'btn-nowrap': nowrap,
    }"
  >
    <slot />
  </q-btn>
</template>

<script setup lang="ts">
import type { QBtnProps } from "quasar";

const props = defineProps<QBtnProps & { nowrap?: boolean }>();
</script>

<style>
.btn-nowrap > span {
  flex-wrap: nowrap;
}
</style>
