import lang from "quasar/lang/en-US.js"
import iconSet from "quasar/icon-set/material-icons.js"
import { Notify,Loading,Dialog } from "quasar"
import { QTooltip,QBtn,QCard } from "quasar"

export const componentsWithDefaults = { QTooltip,QBtn,QCard }

export const appConfigKey = "nuxtQuasar"

export const quasarNuxtConfig = {
  lang,
  iconSet,
  components: {"defaults":{"QTooltip":{"delay":350},"QBtn":{"noCaps":true},"QCard":{"bordered":true}}},
  plugins: {Notify,Loading,Dialog},
  
}