<template>
  <q-img :src="image" fit="contain" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useDarkMode } from "~/composables/useDarkMode";

type LogoVariant =
  | "ncb-square"
  | "ncb-tech"
  | "ncb-solac"
  | "haz-validate"
  | "wsc-square"
  | "wsc";

const props = defineProps<{ variant: LogoVariant }>();
type LogoFileName<Suffix extends string> =
  `/images/${string}${LogoVariant}${Suffix}.${"svg" | "png"}`;

type LightLogoFileName = LogoFileName<"">;
type DarkLogoFileName = LogoFileName<"-dark" | "">;

const logoVariants: Record<
  LogoVariant,
  { lightMode: LightLogoFileName; darkMode: DarkLogoFileName }
> = {
  "ncb-square": {
    lightMode: "/images/brand/ncb-square.svg",
    darkMode: "/images/brand/ncb-square-dark.svg",
  },
  "ncb-solac": {
    darkMode: "/images/brand/ncb-solac-dark.svg",
    lightMode: "/images/brand/ncb-solac.svg",
  },
  "ncb-tech": {
    lightMode: "/images/brand/ncb-tech.svg",
    darkMode: "/images/brand/ncb-tech-dark.svg",
  },
  "haz-validate": {
    lightMode: "/images/assets/haz-validate.svg",
    darkMode: "/images/assets/haz-validate-dark.svg",
  },
  "wsc-square": {
    lightMode: "/images/brand/wsc-square.png",
    darkMode: "/images/brand/wsc-square.png",
  },
  wsc: {
    lightMode: "/images/brand/wsc.png",
    darkMode: "/images/brand/wsc-dark.png",
  },
};

const dark = useDarkMode();
const image = computed(() => {
  const variant = props.variant || "square";
  return logoVariants[variant][dark.value ? "darkMode" : "lightMode"];
});
</script>
