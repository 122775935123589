import { unref, type MaybeRef } from "vue";
import { useOurNuxtApp } from "~/utils/nuxt";
import { createMutation, createQuery } from "~/utils/queryUtils";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { parseUriTemplate } from "~/utils/uriTemplates";

import type { UsersGetSchema } from "~/server/api/cm/users/index.get";
import type { RailLinkPutBody } from "~/server/api/auth/user/rail.put";

const endpoints = {
  list: "/api/cm/users",
  get: parseUriTemplate("/api/cm/users/{id}"),
  railLinks: { get: "/api/auth/user/rail", set: "/api/auth/user/rail" },
} as const;

export const useUserService = () => {
  const { $api } = useOurNuxtApp();

  // Case List
  const listUsers = (
    signal?: AbortSignal,
    search?: string,
    page = 1,
    pageSize = 50
  ) => {
    return $api(endpoints.list, {
      method: "get",
      params: {
        pageSize,
        search,
        page,
      } satisfies UsersGetSchema,
      signal: signal,
    });
  };

  type Options = { lazy?: boolean; createNuxtError?: boolean };
  const useListUsersQuery = (x?: Options) => {
    return createQuery(
      [QUERY_KEYS.Users.list],
      ({ signal }) => listUsers(signal),
      {
        createNuxtError: x?.createNuxtError,
        suspense: !x?.lazy,
      }
    );
  };

  const getUser = async (id: string, signal?: AbortSignal) => {
    const result = await $api(endpoints.get.expand({ id }), { signal });
    return result || null;
  };

  const useGetUserQuery = async (
    id: MaybeRef<string | null>,
    createNuxtError = true,
    suspense: boolean = true
  ) => {
    return createQuery(
      [QUERY_KEYS.Users.get, id],
      ({ signal }) => {
        const _id = unref(id);
        if (_id) return getUser(_id, signal);
        return Promise.resolve(null);
      },
      {
        createNuxtError,
        suspense,
      }
    );
  };

  const getRailLinks = async (signal?: AbortSignal) => {
    const result = await $api(endpoints.railLinks.get, { signal });
    return result ?? null;
  };

  const useRailLinksQuery = () => {
    return createQuery(
      [QUERY_KEYS.Users.Rail.get],
      ({ signal }) => getRailLinks(signal),
      { staticData: true }
    );
  };

  const setRailLinks = async (railLinks: RailLinkPutBody) => {
    return $api(endpoints.railLinks.set, { method: "PUT", body: railLinks });
  };

  const useRailLinksMutation = () => {
    return createMutation(setRailLinks, {
      showSuccess: false,
      showError: false,
    });
  };

  return {
    listUsers,
    useListUsersQuery,
    useGetUserQuery,
    useRailLinksQuery,
    getRailLinks,
    useRailLinksMutation,
  };
};
