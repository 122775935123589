import { useQueryClient } from "@tanstack/vue-query";
import { unref, type MaybeRef } from "vue";
import useNotify from "~/composables/useNotify";
import { useOurNuxtApp } from "~/utils/nuxt";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";

import type {
  TemplateListQuery,
  UpsertTemplatePayload,
} from "~/src/models/Template/Template.model";
import type {
  CreateTemplateGroupPayload,
  TemplateGroupListQueryType,
  UpdateTemplateGroupPayload,
} from "~/src/models/Template/TemplateGroup.model";
import type { Replace } from "~/utils/helpers";

const endpoints = {
  group: {
    list: "/api/hcd/template-groups/list",
    get: parseUriTemplate("/api/hcd/template-groups/{id}"),
    create: "/api/hcd/template-groups",
    update: parseUriTemplate("/api/hcd/template-groups/{id}"),
    delete: parseUriTemplate("/api/hcd/template-groups/{id}"),
  },
  template: {
    list: parseUriTemplate("/api/hcd/template-groups/{id}/list"),
    get: parseUriTemplate(
      "/api/hcd/template-groups/{id}/templates/{templateId}"
    ),
    create: parseUriTemplate("/api/hcd/template-groups/{id}/templates"),
    update: parseUriTemplate(
      "/api/hcd/template-groups/{id}/templates/{templateId}"
    ),
    delete: parseUriTemplate(
      "/api/hcd/template-groups/{id}/templates/{templateId}"
    ),
  },
} as const;

export const useTemplateGroupService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();

  const listTemplateGroups = (body: TemplateGroupListQueryType) =>
    $api(endpoints.group.list, {
      query: body,
    });

  type Local = MaybeRef<TemplateGroupListQueryType["localOrShared"]>;
  const useListTemplateGroupsQuery = (
    {
      localOrShared,
      ...body
    }: Replace<
      TemplateGroupListQueryType,
      {
        localOrShared: Local;
      }
    >,
    options?: MyQueryOptions
  ) => {
    return createQuery(
      [QUERY_KEYS.TemplateGroups.list, localOrShared],
      () =>
        listTemplateGroups({
          ...body,
          localOrShared: unref(localOrShared),
        }),
      options
    );
  };

  const getTemplateGroup = (id: string) =>
    $api(endpoints.group.get.expand({ id }));

  const useGetTemplateGroupQuery = (
    id: MaybeRef<string | null | undefined>,
    options?: MyQueryOptions
  ) => {
    return createQuery(
      [QUERY_KEYS.TemplateGroups.get, id],
      () => {
        const val = unref(id);
        if (!val || val === "new") return undefined;
        return getTemplateGroup(val);
      },
      options
    );
  };

  const createTemplateGroup = (body: CreateTemplateGroupPayload) =>
    $api(endpoints.group.create, {
      method: "POST",
      body: body,
    });

  const useCreateTemplateGroupMutation = () =>
    createMutation(
      (body: CreateTemplateGroupPayload) => createTemplateGroup(body),
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.TemplateGroups.list],
          });
          notifySuccess(
            t("success.success"),
            t("success.template_group_added")
          );
        },
        onError: (e) => {
          notifyError(e);
        },
      }
    );

  const updateTemplateGroup = (id: string, body: UpdateTemplateGroupPayload) =>
    $api(endpoints.group.update.expand({ id }), {
      method: "PUT",
      body: body,
    });

  const useUpdateTemplateGroupMutation = () =>
    createMutation(
      ({ id, body }: { id: string; body: UpdateTemplateGroupPayload }) =>
        updateTemplateGroup(id, body),
      {
        onSuccess: (_, { id }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.TemplateGroups.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.TemplateGroups.get, id],
          });
          notifySuccess(
            t("success.success"),
            t("success.template_group_updated")
          );
        },
        onError: (e) => {
          notifyError(e);
        },
      }
    );

  const deleteTemplateGroup = (id: string) =>
    $api(endpoints.group.delete.expand({ id }), {
      method: "DELETE",
    });

  const useDeleteTemplateGroupMutation = () =>
    createMutation((id: string) => deleteTemplateGroup(id), {
      onSuccess: (_x) => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.TemplateGroups.list],
        });
        notifySuccess(
          t("success.success"),
          t("success.template_group_deleted")
        );
      },
      onError: (e) => {
        notifyError(e);
      },
    });

  // TEMPLATES
  const listTemplates = async (id: string, query: TemplateListQuery) =>
    $api(endpoints.template.list.expand({ id }), {
      query,
    });

  const useListTemplatesQuery = (
    id: MaybeRef<string | null | undefined>,
    queryParams: TemplateListQuery,
    options?: MyQueryOptions
  ) => {
    return createQuery(
      [QUERY_KEYS.TemplateGroups.Templates.list, id],
      () => {
        const val = unref(id);
        if (!val) return null;
        return listTemplates(val, queryParams);
      },
      options
    );
  };

  // TEMPLATE
  // Could use zod type to grab id and transform to a name
  const getTemplate = (id: string, templateId: string) =>
    $api(endpoints.template.get.expand({ id, templateId }));

  const useGetTemplateQuery = (
    id: string,
    templateId: string,
    options?: MyQueryOptions
  ) => {
    return createQuery(
      [QUERY_KEYS.TemplateGroups.Templates.get, id, templateId],
      () => {
        const val = unref(id);
        if (!val || val === "new") return undefined;
        return getTemplate(id, templateId);
      },
      options
    );
  };

  const addTemplate = async (id: string, body: UpsertTemplatePayload) =>
    $api(endpoints.template.create.expand({ id }), {
      method: "POST",
      body: body,
    });

  const useCreateTemplateMutation = () =>
    createMutation(
      ({ id, body }: { id: string; body: UpsertTemplatePayload }) =>
        addTemplate(id, body),
      {
        onSuccess: (_x, { id }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.TemplateGroups.Templates.list, id],
          });
          notifySuccess(t("success.success"), t("success.template_added"));
        },
        onError: (e) => {
          notifyError(e);
        },
      }
    );

  const updateTemplate = (
    id: string,
    templateId: string,
    body: UpsertTemplatePayload
  ) =>
    $api(endpoints.template.update.expand({ id, templateId }), {
      method: "PUT",
      body: body,
    });

  const useUpdateTemplateMutation = () =>
    createMutation(
      ({
        id,
        templateId,
        body,
      }: {
        id: string;
        templateId: string;
        body: UpsertTemplatePayload;
      }) => updateTemplate(id, templateId, body),
      {
        onSuccess: (_x, { id, templateId }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.TemplateGroups.Templates.list, id],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.TemplateGroups.Templates.get, id, templateId],
          });
          notifySuccess(t("success.success"), t("success.template_updated"));
        },
        onError: (e) => {
          notifyError(e);
        },
      }
    );

  const deleteTemplate = (id: string, templateId: string) =>
    $api(endpoints.template.delete.expand({ id, templateId }), {
      method: "DELETE",
    });

  const useDeleteTemplateMutation = () =>
    createMutation(
      ({ id, templateId }: { id: string; templateId: string }) =>
        deleteTemplate(id, templateId),
      {
        onSuccess: (_x, { id }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.TemplateGroups.Templates.list, id],
          });
          notifySuccess(t("success.success"), t("success.template_deleted"));
        },
        onError: (e) => {
          notifyError(e);
        },
      }
    );

  return {
    // Template Groups
    listTemplateGroups,
    useListTemplateGroupsQuery,
    useGetTemplateGroupQuery,
    useCreateTemplateGroupMutation,
    useUpdateTemplateGroupMutation,
    useDeleteTemplateGroupMutation,

    // Templates
    useListTemplatesQuery,
    listTemplates,
    useGetTemplateQuery,
    useCreateTemplateMutation,
    useUpdateTemplateMutation,
    useDeleteTemplateMutation,
  };
};
