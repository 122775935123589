import { useCaseService } from "~/src/services/CaseService";
import { CaseStatus } from "~/src/models/Case/Shared.model";
import { defineNuxtRouteMiddleware, navigateTo } from "#imports";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { useGetCaseQuery } = useCaseService();
  const id = decodeURIComponent(String(to.params.caseId));
  const { data: result } = await useGetCaseQuery(id);

  const status = result.value?.caseStatus?.status;

  const goToHistory =
    status === CaseStatus.InProgress || status === CaseStatus.Closed;

  if (
    goToHistory &&
    to.path !==
      `/cases/${encodeURIComponent(String(to.params.caseId))}/history` &&
    from.name === "cases"
  )
    return navigateTo(
      `/cases/${encodeURIComponent(String(to.params.caseId))}/history`
    );
  else return;
});
