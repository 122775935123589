<template>
  <div
    class="full-width add-border remove-border--x remove-border--top q-px-sm layout-wrapper-bg flex-row items-center justify-end"
  >
    <Button
      v-if="!isLoggedIn"
      :class="['icon--grey loginButton', $q.screen.lt.md ? 'q-px-xs' : '']"
      flat
      icon="mdi-login-variant"
      :label="$t('user.sign_in')"
      :href="getLoginUrl()"
    />
    
    <LayoutAvatar v-if="isLoggedIn" has-menu />
  </div>
</template>

<script lang="ts" setup>
import { useQuasar } from "quasar";
import { LayoutAvatar } from "#components";
import useAuth from "~/composables/useAuth";

const { isLoggedIn, getLoginUrl } = await useAuth();

const $q = useQuasar();
</script>

<style lang="scss" scoped>
hr {
  margin-block-end: 0;
  margin: 0;
}
</style>
