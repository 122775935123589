<template>
  <ClientOnly>
    <Draggable
      v-model="localModel"
      :component-data="{
        class: wrapperClasses ?? 'q-list',
        type: 'transition-group',
        name: !drag ? 'flip-list' : null,
      }"
      :group="dragGroup ?? 'description'"
      :handle="handle === false ? undefined : (handle ?? '.drag-handle')"
      v-bind="dragOptions"
      item-key="name"
      @start="drag = true"
      @end="drag = false"
    >
      <!-- Have to wrap with div to get the slot to work -->
      <template #item="{ element }">
        <div :class="itemWrapperClasses ?? ''">
          <slot
            name="listItem"
            v-bind="{
              item: element as DraggableListOption<K>,
              isDragging: drag,
            }"
          >
            <DraggableListItem
              v-model:selected="element.selected"
              :label="element.name"
              :drag="drag"
            />
          </slot>
        </div>
      </template>
    </Draggable>
    <template #fallback>
      <q-list>
        <div
          v-for="(opt, i) in modelValue"
          :key="i"
          :class="itemWrapperClasses ?? ''"
        >
          <slot
            name="listItem"
            v-bind="{
              item: opt as DraggableListOption<K>,
              isDragging: drag,
            }"
          >
            <DraggableListItem
              :drag="false"
              :selected="opt.selected"
              :label="opt.name"
            />
          </slot>
        </div>
      </q-list>
    </template>
  </ClientOnly>
</template>

<script setup lang="ts" generic="K">
import { ClientOnly, DraggableListItem } from "#components";
import { computed, ref } from "vue";

import Draggable from "vuedraggable";

export type DraggableListOption<T> = {
  name: string;
  value: T;
  selected?: boolean;
};

const dragOptions = {
  animation: 200,
  disabled: false,
  ghostClass: "ghost",
};

const props = defineProps<{
  modelValue: DraggableListOption<K>[];
  dragGroup?: string;
  wrapperClasses?: string;
  itemWrapperClasses?: string;
  handle?: string | false;
}>();

const drag = ref(false);
const emit = defineEmits<{
  (event: "update:modelValue", payload: typeof props.modelValue): void;
}>();

const localModel = computed({
  get: () => props.modelValue,
  set: (val) => {
    emit("update:modelValue", val);
  },
});

defineExpose({
  drag,
});
</script>

<style lang="scss" scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.dark-mode {
  .ghost {
    opacity: 0.5;
    background: #878787;
  }
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>
