import { useQueryClient } from "@tanstack/vue-query";
import useNotify from "~/composables/useNotify";
import { getErrorMessagesFromError } from "~/utils/helpers";
import { useOurNuxtApp } from "~/utils/nuxt";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  type MyQueryOptions,
} from "~/utils/queryUtils";

import type { InternalApi } from "nitropack";
import type { TestServiceHookPayload } from "~/server/api/hcd/service-hooks/[id]/test.post";
import type { ListServiceHooksQuery } from "~/server/api/hcd/service-hooks/index.get";
import type { UpsertServiceHookPayload } from "~/server/utils/serviceHooks";
import type { RequestObject } from "../models/utils/Api.model";

export const useServiceHookService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();

  const listServiceHooks = async (req: RequestObject, _signal?: AbortSignal) =>
    await $api<InternalApi["/api/hcd/service-hooks"]["get"]>(
      `/api/hcd/service-hooks/`,
      {
        query: req satisfies ListServiceHooksQuery,
      }
    );

  // List Service Hooks
  const useListServiceHooksQuery = (
    req = {
      page: 1,
      pageSize: 20,
    },
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.ServiceHook.list],
      ({ signal }) =>
        listServiceHooks({ page: req.page, pageSize: req.pageSize }, signal),
      {
        createNuxtError,
      }
    );

  const getServiceHook = async (id: string, signal?: AbortSignal) =>
    await $api<InternalApi["/api/hcd/service-hooks/:id"]["get"]>(
      `/api/hcd/service-hooks/${id}`,
      { signal }
    );

  const useGetServiceHookQuery = (id: string, options?: MyQueryOptions) =>
    createQuery(
      [QUERY_KEYS.ServiceHook.get, id],
      ({ signal }) => getServiceHook(id, signal),
      {
        createNuxtError: false,
        ...options,
      }
    );

  // Add/Update/Delete
  const deleteServiceHook = async (id: string) =>
    await $api(`/api/hcd/service-hooks/${id}`, {
      method: "DELETE",
    });

  const useDeleteServiceHookMutation = () =>
    createMutation(({ id }: { id: string }) => deleteServiceHook(id), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.ServiceHook.list],
        });
        notifySuccess(t("success.success"), t("success.service_hook_deleted"));
      },
      onError: () => {
        notifyError("error.deleting_service_hook");
      },
    });

  const addServiceHook = async (
    payload: UpsertServiceHookPayload,
    _signal?: AbortSignal
  ) =>
    await $api<InternalApi["/api/hcd/service-hooks"]["post"]>(
      "/api/hcd/service-hooks/",
      {
        method: "POST",
        body: payload,
      }
    );

  const useAddServiceHookMutation = () =>
    createMutation(
      (payload: UpsertServiceHookPayload) => addServiceHook(payload),
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.ServiceHook.list],
          });
          notifySuccess(t("success.success"), t("success.service_hook_added"));
        },
        onError: (error) =>
          notifyError(getErrorMessagesFromError(error).join("\n")),
      }
    );

  const updateServiceHook = async (
    { id, payload }: { id: string; payload: UpsertServiceHookPayload },
    _signal?: AbortSignal
  ) =>
    await $api<InternalApi["/api/hcd/service-hooks/:id"]["put"]>(
      `/api/hcd/service-hooks/${id}`,
      {
        method: "PUT",
        body: payload,
      }
    );

  const useUpdateServiceHookMutation = () =>
    createMutation(
      ({ id, payload }: { id: string; payload: UpsertServiceHookPayload }) =>
        updateServiceHook({ id, payload }),
      {
        onSuccess: (_x, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.ServiceHook.get, y.id],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.ServiceHook.list],
          });
          notifySuccess(
            t("success.success"),
            t("success.service_hook_updated")
          );
        },
        onError: (error) =>
          notifyError(getErrorMessagesFromError(error).join("\n")),
      }
    );

  const testServiceHook = async (
    id: string,
    payload: TestServiceHookPayload,
    _signal?: AbortSignal
  ) =>
    await $api<InternalApi["/api/hcd/service-hooks/:id/test"]["post"]>(
      `/api/hcd/service-hooks/${id}/test`,
      {
        method: "POST",
        body: payload,
      }
    );

  const useTestServiceHookMutation = () =>
    createMutation(
      ({ id, payload }: { id: string; payload: TestServiceHookPayload }) =>
        testServiceHook(id, payload)
    );

  return {
    listServiceHooks,
    useListServiceHooksQuery,
    useGetServiceHookQuery,
    useDeleteServiceHookMutation,
    useAddServiceHookMutation,
    useUpdateServiceHookMutation,
    useTestServiceHookMutation,
  };
};
