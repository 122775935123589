import { useQueryClient } from "@tanstack/vue-query";
import useNotify from "~/composables/useNotify";
import { getErrorMessagesFromError } from "~/utils/helpers";
import { useOurNuxtApp } from "~/utils/nuxt";
import { QUERY_KEYS } from "~/utils/queryKeys";
import { createMutation, createQuery } from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";

import type { UpsertPolicyPayload } from "~/server/utils/policy";
import type { MyQueryOptions } from "~/utils/queryUtils";
import type { RequestObject } from "../models/utils/Api.model";
import type { ListPoliciesQuery } from "~/server/api/hcd/policies/index.get";

const endpoints = {
  list: "/api/hcd/policies",
  get: parseUriTemplate("/api/hcd/policies/{id}"),
  enable: parseUriTemplate("/api/hcd/policies/{id}/enable"),
  disable: parseUriTemplate("/api/hcd/policies/{id}/disable"),
  create: "/api/hcd/policies",
  update: parseUriTemplate("/api/hcd/policies/{id}"),
  delete: parseUriTemplate("/api/hcd/policies/{id}"),
} as const;

export const usePolicyService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotify();

  const listPolicies = (req: RequestObject, signal?: AbortSignal) =>
    $api(endpoints.list, {
      query: req satisfies ListPoliciesQuery,
      signal,
    });

  const useListPoliciesQuery = (
    req: RequestObject = {
      page: 1,
      pageSize: 20,
    },
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Policy.list],
      ({ signal }) => listPolicies(req, signal),
      {
        createNuxtError,
      }
    );

  const getPolicyEmbedded = (id: string, signal?: AbortSignal) =>
    $api(endpoints.get.expand({ id }), { signal });

  const useGetPolicyQuery = (
    id: string,
    createNuxtError = true,
    options?: MyQueryOptions
  ) =>
    createQuery(
      [QUERY_KEYS.Policy.get, id],
      ({ signal }) => getPolicyEmbedded(id, signal),
      {
        ...options,
        createNuxtError,
      }
    );

  const setPolicyStatus = (id: string, enable: boolean, signal?: AbortSignal) =>
    $api(
      enable
        ? endpoints.enable.expand({ id })
        : endpoints.disable.expand({ id }),
      {
        method: "PUT",
        signal,
      }
    );

  const useSetPolicyMutation = () =>
    createMutation(
      ({ id, enable }: { id: string; enable: boolean }) =>
        setPolicyStatus(id, enable),
      {
        onSuccess: (_x, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Policy.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Policy.get, y.id],
          });
          notifySuccess(
            t("success.success"),
            y.enable
              ? t("success.policy_enabled")
              : t("success.policy_disabled")
          );
        },
        onError: (_error, policy) =>
          policy.enable
            ? notifyError(t("error.policy_enabled"))
            : notifyError(t("error.policy_enabled")),
      }
    );

  const addPolicy = (payload: UpsertPolicyPayload) =>
    $api(endpoints.create, {
      method: "POST",
      body: payload,
    });

  const useAddPolicyMutation = () =>
    createMutation((payload: UpsertPolicyPayload) => addPolicy(payload), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Policy.list],
        });
        notifySuccess(t("success.success"), t("success.policy_added"));
      },
      onError: (error) =>
        notifyError(getErrorMessagesFromError(error).join("\n")),
    });

  const updatePolicy = (id: string, payload: UpsertPolicyPayload) =>
    $api(endpoints.update.expand({ id }), {
      method: "PUT",
      body: payload,
    });

  const useUpdatePolicyMutation = () =>
    createMutation(
      ({ id, payload }: { id: string; payload: UpsertPolicyPayload }) =>
        updatePolicy(id, payload),
      {
        onSuccess: (_x, y) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Policy.get, y.id],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.Policy.list],
          });
          notifySuccess(t("success.success"), t("success.policy_updated"));
        },
        onError: (error) =>
          notifyError(getErrorMessagesFromError(error).join("\n")),
      }
    );

  // goes through catchall proxy route
  const deletePolicy = (id: string) =>
    $api<undefined>(endpoints.delete.expand({ id }), {
      method: "DELETE",
    });

  const useDeletePolicyMutation = () =>
    createMutation(({ id }: { id: string }) => deletePolicy(id), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.Policy.list],
        });
        notifySuccess(t("success.success"), t("success.policy_deleted"));
      },
      onError: () => {
        notifyError(t("error.policy_delete"));
      },
    });

  return {
    useListPoliciesQuery,
    listPolicies,
    useGetPolicyQuery,
    useSetPolicyMutation,
    useDeletePolicyMutation,
    deletePolicy,
    useAddPolicyMutation,
    useUpdatePolicyMutation,
  };
};
