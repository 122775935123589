import { useQueryClient } from "@tanstack/vue-query";
import useNotify from "~/composables/useNotify";
import { useOurNuxtApp } from "~/utils/nuxt";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";

import type { ListDataBlocksQuery } from "~/server/api/hcd/data-blocks/list.get";
import type { AddDataBlockPayload } from "~/src/models/DataBlock/DataBlock.model";

const endpoints = {
  list: "/api/hcd/data-blocks/list",
  get: parseUriTemplate("/api/hcd/data-blocks/{id}"),
  create: "/api/hcd/data-blocks",
  update: parseUriTemplate("/api/hcd/data-blocks/{id}"),
  delete: parseUriTemplate("/api/hcd/data-blocks/{id}"),
} as const;

export const useDataBlockService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();

  const listDataBlocks = (query: {
    page: number;
    pageSize: number;
    sortBy?: string;
    sortOrder?: string;
    localOrShared: string;
    search?: string;
  }) =>
    $api(endpoints.list, {
      query: query satisfies ListDataBlocksQuery,
    });

  const getDataBlock = (id: string) => {
    return $api(endpoints.get.expand({ id }), {
      method: "GET",
    });
  };

  const useGetDataBlockQuery = (id: string, options?: MyQueryOptions) => {
    return createQuery(
      [QUERY_KEYS.DataBlocks.get, id],
      () => getDataBlock(id),
      options
    );
  };

  const addDataBlock = (body: AddDataBlockPayload) => {
    return $api(endpoints.create, {
      method: "POST",
      body: body,
    });
  };

  const useAddDataBlockMutation = () =>
    createMutation((body: AddDataBlockPayload) => addDataBlock(body), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.DataBlocks.list],
        });
        notifySuccess(t("success.success"), t("success.data_block_added"));
      },
      onError: (e) => notifyError(e),
    });

  const updateDataBlock = (id: string, body: AddDataBlockPayload) => {
    return $api(endpoints.update.expand({ id }), {
      method: "PUT",
      body: body,
    });
  };

  const useUpdateDataBlockMutation = () =>
    createMutation(
      ({ id, body }: { id: string; body: AddDataBlockPayload }) =>
        updateDataBlock(id, body),
      {
        onSuccess: (_x, { id }) => {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.DataBlocks.list],
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.DataBlocks.get, id],
          });
          notifySuccess(t("success.success"), t("success.data_block_updated"));
        },
        onError: (e) => notifyError(e),
      }
    );

  const deleteDataBlock = (id: string) => {
    return $api(endpoints.delete.expand({ id }), {
      method: "DELETE",
    });
  };

  const useDeleteDataBlockMutation = () =>
    createMutation((id: string) => deleteDataBlock(id), {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.DataBlocks.list],
        });
        notifySuccess(t("success.success"), t("success.data_block_deleted"));
      },
      onError: (e) => notifyError(e),
    });

  return {
    listDataBlocks,
    useAddDataBlockMutation,
    useGetDataBlockQuery,
    useUpdateDataBlockMutation,
    useDeleteDataBlockMutation,
  };
};
